import React, { useState, useRef, useLayoutEffect, useCallback } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themesAnimated from "@amcharts/amcharts4/themes/animated";
import am4lang_pt_BR from "@amcharts/amcharts4/lang/pt_BR";

import requests from "../../../services/requests";
import ClipLoader from "react-spinners/ClipLoader";
import { colors } from "../../../assets/colors";
import { customLabels, customLegend } from "../shared/layout";

am4core.useTheme(am4themesAnimated);

function GraphPortVersusBoardingVersusContainers({ filterInformation, searchString, searchPeriod }) {
    const x = useRef(null);

    const [load, setLoad] = useState(false);

    const getData = useCallback(async chart => {
        const res = await requests.dashboardBoardings({
            search: searchString,
            filters: filterInformation,
            period: searchPeriod,
        });

        chart.data = res;
        setLoad(false);
    });

    useLayoutEffect(() => {
        // Create chart instance
        const chart = am4core.create("portBoardingContainersGraph", am4charts.XYChart);
        chart.language.locale = am4lang_pt_BR;
        chart.colors.list = [am4core.color(colors.roxo_3)];

        // Export
        // chart.exporting.menu = new am4core.ExportMenu();

        /* Create axes */
        const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "port";
        categoryAxis.renderer.minGridDistance = 20;

        // Configure axis label
        const label = categoryAxis.renderer.labels.template;

        customLabels(categoryAxis.renderer.labels, 13);

        label.truncate = true;
        label.maxWidth = 200;
        label.tooltipText = "{category}";

        categoryAxis.events.on("sizechanged", function (ev) {
            var axis = ev.target;
            var cellWidth = axis.pixelWidth / (axis.endIndex - axis.startIndex);
            if (cellWidth < axis.renderer.labels.template.maxWidth) {
                axis.renderer.labels.template.rotation = -40;
                axis.renderer.labels.template.horizontalCenter = "right";
                axis.renderer.labels.template.verticalCenter = "middle";
            } else {
                axis.renderer.labels.template.rotation = 0;
                axis.renderer.labels.template.horizontalCenter = "middle";
                axis.renderer.labels.template.verticalCenter = "top";
            }
        });

        /* Create value axis */
        const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.minGridDistance = 30;

        valueAxis.min = 0;

        const valueLabel = valueAxis.renderer.labels;
        customLabels(valueLabel);

        const legend = customLegend(chart);
        legend.markers.template.width = 10;
        legend.markers.template.height = 10;

        /* Create series */
        const columnSeries = chart.series.push(new am4charts.ColumnSeries());
        columnSeries.name = "Containers";
        columnSeries.dataFields.valueY = "containerQtt";
        columnSeries.dataFields.categoryX = "port";
        columnSeries.columns.template.width = am4core.percent(60);

        columnSeries.tooltip.pointerOrientation = "down";
        columnSeries.tooltip.label.textAlign = "middle";
        columnSeries.columns.template.tooltipY = 0;
        columnSeries.columns.template.tooltipText =
            "[#fff font-size: 15px]{name} em {categoryX}:\n[/][#fff font-size: 20px]{valueY}[/] [#fff]{additional}[/]";

        const lineSeries = chart.series.push(new am4charts.LineSeries());
        lineSeries.name = "Embarques";
        lineSeries.dataFields.valueY = "processQtt";
        lineSeries.dataFields.categoryX = "port";
        lineSeries.strokeDasharray = 5.5;
        lineSeries.stroke = am4core.color("#191970");
        lineSeries.strokeWidth = 3;

        lineSeries.tooltip.label.textAlign = "middle";
        lineSeries.tooltip.pointerOrientation = "up";
        lineSeries.tooltipText =
            "[#fff font-size: 15px]{name} em {categoryX}:\n[/][#fff font-size: 20px]{valueY}[/] [#fff]{additional}[/]";

        chart.cursor = new am4charts.XYCursor();

        getData(chart);

        x.current = chart;

        return () => {
            chart.dispose();
        };
    }, [searchString, filterInformation, searchPeriod]);

    return (
        <>
            {load && <ClipLoader size={20} loading={load} />}

            <div id="portBoardingContainersGraph" style={{ width: "100%", height: "550px" }} />
        </>
    );
}

export default GraphPortVersusBoardingVersusContainers;
