import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import Card from "../../components/Cards";
import Layout from "../../components/Layout";
import SEO from "../../components/SEO";
import TitleC6 from "../../components/Typography/TitleC6";
import GraphBilling from "./../../components/Graphs/GraphBilling";
import GraphProcessByModality from "./../../components/Graphs/GraphProcessByModality";
import GraphProcessByChannel from "./../../components/Graphs/GraphProcessByChannel";
import GraphProcessByStage from "./../../components/Graphs/GraphProcessByStage";
import GraphPortVersusBoardingVersusContainers from "./../../components/Graphs/GraphPortVersusBoardingVersusContainers";
import GraphNationalizationTax from "../../components/Graphs/GraphNationalizationTax";
import Submenu from "./../../components/Menus/Submenu";
import SLATable from "./components/Tables/SLATable";
import requests from "../../services/requests";
import { BsArrowDown, BsArrowUp } from "react-icons/bs";

const AnalyticsPage = () => {
    const filterInformation = useSelector(state => state.filter.value);
    const searchPeriod = useSelector(state => state.period);
    const [searchString, setSearchString] = useState("");
    const [billingData, setBillingData] = useState(null);

    const calculateBilling = () => {
        requests
            .dashboardProcessBilling({
                search: searchString,
                filters: filterInformation,
                period: searchPeriod,
            })
            .then(data => {
                setBillingData(data);
            });
    };

    useEffect(() => {
        calculateBilling();
    }, [searchString, filterInformation, searchPeriod]);

    return (
        <Layout>
            <SEO title="Analytics" />
            <Submenu type={{ filters: true }} />
            <div className="p-4">
                <Card>
                    <TitleC6>Métricas</TitleC6>
                    <div className="border-t border-C2 border-solid pt-4 pb-12">
                        <div className="flex mt-5">
                            <div className="w-full">
                                <h6 className="">Tipos de operação</h6>
                                <GraphProcessByModality
                                    filterInformation={filterInformation}
                                    searchString={searchString}
                                    searchPeriod={searchPeriod}
                                />

                                <h6 className="mt-10">Processos por etapa</h6>
                                <GraphProcessByStage
                                    filterInformation={filterInformation}
                                    searchString={searchString}
                                    searchPeriod={searchPeriod}
                                />

                                <h6 className="mt-10">Parametrização - Canal</h6>
                                <GraphProcessByChannel
                                    filterInformation={filterInformation}
                                    searchString={searchString}
                                    searchPeriod={searchPeriod}
                                />
                            </div>
                        </div>
                        <div className="w-full mt-5">
                            <h6 className="mt-10">Porto x Embarques x Containers</h6>
                            <GraphPortVersusBoardingVersusContainers
                                filterInformation={filterInformation}
                                searchString={searchString}
                                searchPeriod={searchPeriod}
                            />
                        </div>
                        <div className="flex mt-5">
                            <div className="w-2/12">
                                <h6 className="mt-5">Faturamento (R$)</h6>
                                <div className="billing">
                                    <div className="ml-4 mb-12">
                                        <h6 className="text-roxo_oficial font-normal mt-5 w-auto">Total faturamento</h6>
                                        <h2 className="text-roxo_oficial font-bold">R$ {billingData?.summary?.total}</h2>

                                        <h6 className="text-roxo_oficial font-normal mt-5">Total Vendemmia</h6>
                                        <div className="flex">
                                            <span className="text-roxo_oficial font-bold float-left text-lg">
                                                R$ {billingData?.summary?.totalPreviousMonth1} no último mês
                                                <span className="flex float-right">
                                                    {billingData?.summary?.percentMonth1 > 0 ? (
                                                        <>
                                                            <BsArrowUp size={20} color="#00db00" /> <span className="font-bold canal-verde">{billingData?.summary?.percentMonth1}%</span>
                                                        </>
                                                    ): (
                                                        <>
                                                            <BsArrowDown size={20} color="#EC5D66" /> <span className="font-bold canal-vermelho">{billingData?.summary?.percentMonth1}%</span>
                                                        </>
                                                    )}
                                                </span>
                                            </span>
                                        </div>
                                        <h6 className="text-roxo_oficial font-normal mt-5">Total Gestão/Próprio</h6>
                                        <p className="flex text-roxo_oficial font-bold text-lg">
                                            R$ {billingData?.summary?.totalPreviousMonth2} no último mês
                                            {billingData?.summary?.percentMonth2 !== 0 && (
                                                <span className="flex">
                                                    {billingData?.summary?.percentMonth2 > 0 ? (
                                                        <>
                                                            <BsArrowUp size={20} color="#00db00" /> <span className="font-bold canal-verde">{billingData?.summary?.percentMonth2}%</span>
                                                        </>
                                                    ): (
                                                        <>
                                                            <BsArrowDown size={20} color="#EC5D66" /> <span className="font-bold canal-vermelho">{billingData?.summary?.percentMonth2}%</span>
                                                        </>
                                                    )}
                                                </span>
                                            )}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="w-10/12 mt-10">
                                {billingData && (
                                    <GraphBilling
                                        filterInformation={filterInformation}
                                        searchString={searchString}
                                        searchPeriod={searchPeriod}
                                        countManagementProcess={billingData['client']}
                                        billingData={billingData['result']}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="side-by-side">
                            <div className="mt-5 imp">
                                <h6 className="mt-10">Impostos de Nacionalização (Mil)</h6>
                                <GraphNationalizationTax
                                    filterInformation={filterInformation}
                                    searchString={searchString}
                                    searchPeriod={searchPeriod}
                                />
                            </div>
                            <div className="mt-5 sla">
                                <h6 className="mt-10">SLA (Service Level Agreement)</h6>
                                <SLATable
                                    filterInformation={filterInformation}
                                    searchString={searchString}
                                    searchPeriod={searchPeriod}
                                />
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        </Layout>
    );
};

export default AnalyticsPage;
