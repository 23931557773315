import React, { useState, useEffect, useRef, useLayoutEffect, useCallback } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themesAnimated from "@amcharts/amcharts4/themes/animated";
import am4lang_pt_BR from "@amcharts/amcharts4/lang/pt_BR";

import ClipLoader from "react-spinners/ClipLoader";
import { colors } from "../../../assets/colors";

am4core.useTheme(am4themesAnimated);

const GraphBilling = ({ filterInformation, searchString, searchPeriod, countManagementProcess, billingData }) => {
    const x = useRef(null);
    const [load, setLoad] = useState(true);

    // Create series
    function createAxisAndSeries(chart, field, name, opposite, bullet, color = false) {
        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

        if (chart.yAxes.indexOf(valueAxis) !== 0) {
            valueAxis.syncWithAxis = chart.yAxes.getIndex(0);
        }

        var series = chart.series.push(new am4charts.LineSeries());
        series.dataFields.valueY = field;
        series.dataFields.dateX = "date";
        series.strokeWidth = 2;
        series.yAxis = valueAxis;
        series.name = name;
        series.tooltipText =
            "[#fff font-size: 15px]{name} em {dateFormatted}:\n[/][#fff font-size: 20px]R$ {valueY}[/] [#fff]{additional}[/]";
        series.fill = color ? am4core.color("#E52359") : series.stroke;
        series.stroke = color ? am4core.color(color) : series.stroke;
        series.tensionX = 0.8;
        series.showOnInit = true;

        var interfaceColors = new am4core.InterfaceColorSet();

        switch (bullet) {
            case "triangle":
                var bullet = series.bullets.push(new am4charts.Bullet());
                bullet.width = 12;
                bullet.height = 12;
                bullet.horizontalCenter = "middle";
                bullet.verticalCenter = "middle";

                var triangle = bullet.createChild(am4core.Triangle);
                triangle.stroke = interfaceColors.getFor("background");
                triangle.strokeWidth = 2;
                triangle.direction = "top";
                triangle.width = 12;
                triangle.height = 12;
                break;
            case "rectangle":
                var bullet = series.bullets.push(new am4charts.Bullet());
                bullet.width = 10;
                bullet.height = 10;
                bullet.horizontalCenter = "middle";
                bullet.verticalCenter = "middle";

                var rectangle = bullet.createChild(am4core.Rectangle);
                rectangle.stroke = interfaceColors.getFor("background");
                rectangle.strokeWidth = 2;
                rectangle.width = 10;
                rectangle.height = 10;
                break;
            default:
                var bullet = series.bullets.push(new am4charts.CircleBullet());
                bullet.circle.stroke = interfaceColors.getFor("background");
                bullet.circle.strokeWidth = 2;
                break;
        }

        valueAxis.renderer.line.strokeOpacity = 1;
        valueAxis.renderer.line.strokeWidth = 0.2;
        valueAxis.renderer.labels.template.fill = color ? am4core.color("#E52359") : series.stroke;
        valueAxis.renderer.opposite = opposite;
    }

    // generate some random data, quite different range
    const getData = useCallback(async chart => {
        var chartData = [];
        var date;
        var client;
        var vendemmia;

        for (var i = 0; i < billingData?.length; i++) {
            let aux = billingData[i]["date"].split('-');

            date = new Date(aux[0], parseInt(aux[1]) - 1, aux[2]);

            vendemmia = billingData[i]["qtt"];
            client = billingData[i]["qtt2"];

            if (countManagementProcess > 0) {
                chartData.push({
                    date,
                    dateFormatted: (date?.getMonth() + 1)?.toString()?.padStart(2, "0") + "/" + date?.getFullYear(),
                    client,
                    vendemmia,
                });
            } else {
                chartData.push({
                    date,
                    dateFormatted: (date?.getMonth() + 1)?.toString()?.padStart(2, "0") + "/" + date?.getFullYear(),
                    vendemmia,
                });
            }
        }

        chart.data = chartData;
        setLoad(false);
    });

    useLayoutEffect(() => {
        setLoad(true);

        // Create chart instance
        const chart = am4core.create("billingGraph", am4charts.XYChart);

        chart.colors.list = [am4core.color(colors.roxo_oficial)];
        chart.language.locale = am4lang_pt_BR;

        // Increase contrast by taking evey second color
        chart.colors.step = 2;

        // Create axes
        var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.minGridDistance = 50;

        createAxisAndSeries(chart, "vendemmia", "Vendemmia", false, "circle");

        if (countManagementProcess > 0) {
            createAxisAndSeries(chart, "client", "Gestão/Próprio", true, "rectangle", "#E52359");
        }

        // Add legend
        chart.legend = new am4charts.Legend();

        // Add cursor
        chart.cursor = new am4charts.XYCursor();

        getData(chart);

        x.current = chart;

        return () => {
            chart.dispose();
        };
    }, [billingData]);

    return (
        <>
            {load && <ClipLoader size={20} loading={load} />}
            <div id="billingGraph" style={{ width: "100%", height: "300px" }} />
        </>
    );
};

export default GraphBilling;
