import React, { useState, useEffect, useRef, useLayoutEffect, useCallback } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themesAnimated from "@amcharts/amcharts4/themes/animated";
import am4lang_pt_BR from "@amcharts/amcharts4/lang/pt_BR";
import ClipLoader from "react-spinners/ClipLoader";
import { colors } from "../../../assets/colors";
import { customLabels } from "../shared/layout";
import requests from "../../../services/requests";

am4core.useTheme(am4themesAnimated);

const GraphNationalizationTax = ({ filterInformation, searchString, searchPeriod }) => {
    const x = useRef(null);
    const [load, setLoad] = useState(true);

    const colorsList = {
        II: am4core.color(colors.roxo_oficial),
        IPI: am4core.color(colors.roxo_1),
        PIS: am4core.color(colors.roxo_2),
        COFINS: am4core.color(colors.roxo_3),
        ICMS: am4core.color(colors.roxo_4),
        ICMSST: am4core.color(colors.roxo_5),
    };

    const getData = useCallback(async chart => {
        const res = await requests.dashboardClientTax({
            search: searchString,
            filters: filterInformation,
            period: searchPeriod,
        });

        const aux = [];

        for (let color in colorsList) {
            aux.push({ ...res.find(data => data.tax === color), color: colorsList[color] });
        }

        chart.data = aux;
        setLoad(false);
    });

    useLayoutEffect(() => {
        // Create chart instance
        const chart = am4core.create("graphNationalizationTax", am4charts.XYChart);
        chart.language.locale = am4lang_pt_BR;

        // Export
        // chart.exporting.menu = new am4core.ExportMenu();

        /* Create axes */
        const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "tax";
        categoryAxis.renderer.minGridDistance = 5;

        const categoryLabel = categoryAxis.renderer.labels;
        customLabels(categoryLabel);

        /* Create value axis */
        const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;

        const valueLabel = valueAxis.renderer.labels;
        customLabels(valueLabel);

        /* Create series */
        const series = chart.series.push(new am4charts.ColumnSeries());
        series.columns.template.width = am4core.percent(50);
        series.name = "Impostos";
        series.dataFields.valueY = "value";
        series.dataFields.categoryX = "tax";

        series.columns.template.tooltipText =
            "[#fff font-size: 15px]Valor do {categoryX}:\n[/][#fff font-size: 20px]{valueY}[/] [#fff]{additional}[/]";
        series.tooltip.label.textAlign = "middle";
        series.tooltipText = "{valueY.value}";

        series.columns.template.propertyFields.fillOpacity = "fillOpacity";
        series.columns.template.propertyFields.stroke = "stroke";
        series.columns.template.propertyFields.fill = "color";

        getData(chart);

        chart.cursor = new am4charts.XYCursor();

        x.current = chart;

        return () => {
            chart.dispose();
        };
    }, [searchString, filterInformation, searchPeriod]);

    return (
        <>
            {load && <ClipLoader size={20} loading={load} />}

            <div id="graphNationalizationTax" style={{ width: "500px", height: "480px" }} />
        </>
    );
};

export default GraphNationalizationTax;
